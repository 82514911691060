/********** Document Ready Function ************/

function debouncer( func , timeout ) {
    var timeoutID , timeout = timeout || 200;
    return function () {
        var scope = this , args = arguments;
        clearTimeout( timeoutID );
        timeoutID = setTimeout( function () {
            func.apply( scope , Array.prototype.slice.call( args ) );
        } , timeout );
    }
}

function msgAlert(){
    if($('.ms-alert').length>0){
        $('.ms-alert').fadeIn('slow', function () {
            $(this).delay(10000).fadeOut('slow');
        });
    }
}
msgAlert();

$(document).ready(function(){
    $('[data-toggle="tooltip"]').tooltip();

    var doc = $(document);

    doc.on('click','.ms-alert-x',function() {
        $(this).closest('.ms-alert').fadeOut();
    });

    doc.on('change','#kpi_provider',function() {
        $.ajax({
            dataType: 'script',
            url: '/load_discipline/'+$(this).val()
        });
    });

    doc.on('click','#upload_file_btn',function() {
        $("#financial_analysis_file_file").click();
    });

    doc.on('change','#financial_analysis_file_file',function() {
        $("#new_financial_analysis_file").submit();
    });

    doc.on('keyup','#comments_field',function() {
        console.log($(this).val());
        var len = $(this).val().length;
        if (len >= 501) {
            $(this).val($(this).val().substr(0, 501));
        } else {
            $('#charNum').text(500 - len);

        }

    });

    $('select').selectpicker();
    $('[data-toggle="datepicker"]').datepicker({autoclose:true});

    doc.on('click', '.ScrollToJS', function() {
        var go_to = $(this).attr('href') || $(this).data('href') || '#';
        if($(go_to).length > 0){$('html, body').animate({scrollTop:$(go_to).position().top}, 1500);}
        return false;
    });

    doc.on('change', '#provider_id', function() {
        if($(this).val()!==""){
            window.location.href = location.protocol + '//' + location.host + location.pathname+"?provider_id="+$(this).val();
        }else{
            window.location.href = location.protocol + '//' + location.host + location.pathname;
        }

    });

    //Menu toggle
    doc.on('click', '#left-menu-controller', function () {
        $('body').toggleClass('close-menu');
    });
    doc.on('click', '.left-menu a.arrow, .menu-setting a.arrow', function (e) {
        $(this).next('.left-menu-sub').stop().slideToggle('slow');
        e.preventDefault();
    });


    $('#eyePassword').on('mousedown', function () {
        $("#login_Password").attr('type', 'text');
    }).on('mouseup', function () {
        $("#login_Password").attr('type', 'password');
    });

    if($(".ms-alert").length > 0){
        msgAlert();
    }

});

/************** Window resize ***************/
$(window).resize(debouncer(function(){

}));