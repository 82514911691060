// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "../stylesheets/application";
import "../stylesheets/styles";

const images = require.context('../images', true)


import 'jquery'
import 'popper.js'
require("bootstrap")
import "./main";
import "./bootstrap-select.min";
import "./datepicker.min";
import "./select2.min";




document.addEventListener("turbolinks:load", function() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()
    })
})



Rails.start()
ActiveStorage.start()
window.jQuery = $;
window.$ = $;